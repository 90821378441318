<template>
  <div class="page-content">
    <div class="containerV">
      <!-- 标题 -->
      <div class="headerV">
        <div class="top-tips1">
          <div>{{tipsText}}</div>
        </div>
      </div>
      <!-- 拍摄区域 -->
      <div class="contentV">
        <video ref="video" width="280px" height="280px"  class="camera" ></video>
      </div>
      <canvas ref="canvas" width="280px" height="280px"  style="display: none;"></canvas>
      <!-- 操作区域 -->
      <div class="footerV">
        <div style="width: 100%;">
          <div v-if="!tempImg" style="width: 100%;">
            <div class="take-photo-bgV">
              <!-- 图片上传 -->
              <div v-show="true" class="btn-change-upload" @click="handleChooseImage" />
              <!--拍照-->
              <div class="btn-take-photo" @click="handleTakePhotoClick" />

            </div>
          </div>
          <div class="confirmV" v-else>
            <div class="btn-cancel" @click="handleCancelClick">
              取消
            </div>
            <div class="btn-ok" @click="handleOkClick">
              确定
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import request from "@/api";

export default {
  name: '',
  components: {

  },
  props: {},
  data() {
    return {
      //拍的照片存储的容器
      photoDataUrl: '',
      stream: null,
      videoElement: null,
      canvasElement: null,
      tipsText: '请拍照', // 错误文案提示
      tempImg: '', // 本地图片路径
      cameraEngine: null, // 相机引擎
      devicePosition: true, // 摄像头朝向
      isAuthCamera: true, // 是否拥有相机权限
      form:{
        nameText:'',
        numberText:''
      }
    }
  },
  computed: {
    bgUrl(){
      return require("@/assets/img/head-sculpture.png")
    },
    postureUrl(){
      return require("@/assets/img/posture.png")
    }
  },
  watch: {
  },
  created() {
    this.form.nameText=this.$route.query.nameText
    this.form.numberText=this.$route.query.numberText

    this.openCamera()
  },
  mounted() {
    //通过 ref 获取到的 video 元素赋值给组件实例的 videoElement 属性
    this.videoElement = this.$refs.video;
    //通过 ref 获取到的 canvas 元素赋值给组件实例的 canvasElement 属性
    this.canvasElement = this.$refs.canvas;
  },
  onLoad(options) {

  },
  methods: {

    openCamera() {
      /*
      调用了 navigator.mediaDevices.getUserMedia 方法
      该方法请求用户授权访问本地设备（例如摄像头、麦克风等） 并返回一个 Promise 对象。
      调用该方法时，代码传入一个配置对象，指定要访问的设备类型
      例如 { video: true } 表示要获取视频流。调用 then 方法来处理成功的情况
      */
      navigator.mediaDevices.getUserMedia({ audio: true,video: {
          deviceId: "default",
          facingMode: "user" //调用前置摄像头
        } })
          .then((stream) => {
            //将视频流赋值给 Vue 实例的 stream 属性
            this.stream = stream;
            //视频流绑定到 HTML5 的 video 元素上，使用 srcObject 属性来实现，并调用 play 方法播放视频
            this.videoElement.srcObject = stream;
            this.videoElement.play();
            //通过以上的这样的操作，即可实现摄像头视频流的捕获和展示。
          })
          .catch((error) => {
            console.error('打开摄像头失败：', error);
          });
    },
    handleChangeCameraClick() {  // 切换设备镜头
      this.devicePosition = !this.devicePosition;
    },
    handleChooseImage() {  	// 返回
      window.history.go(-1);

    },
    copyContentH5(content) {
      var copyDom = document.createElement('div');
      copyDom.innerText=content;
      copyDom.style.position='absolute';
      copyDom.style.top='0px';
      copyDom.style.right='-9999px';
      document.body.appendChild(copyDom);
      //创建选中范围
      var range = document.createRange();
      range.selectNode(copyDom);
      //移除剪切板中内容
      window.getSelection().removeAllRanges();
      //添加新的内容到剪切板
      window.getSelection().addRange(range);
      //复制
      var successful = document.execCommand('copy');
      copyDom.parentNode.removeChild(copyDom);
      try{
        var msg = successful ? "successful" : "failed";
        alert("复制成功")
      } catch(err){
        alert("复制失败")
      }
    },
    handleTakePhotoClick() { // 拍照点击、
      //首先获取了 Canvas 元素的上下文对象 context：
      const context = this.canvasElement.getContext('2d');
      //通过 context.drawImage 方法将视频元素中当前帧的图像绘制到 Canvas 中：
      /*
          this.videoElement 表示视频元素
          0 和 0 表示图像在 Canvas 中的起始坐标
          this.canvasElement.width 和 this.canvasElement.height 表示图像在 Canvas 中的宽度和高度。
          这一步是实现了将当前摄像头所捕获的图像绘制到 Canvas 中，以便后续可以处理图像。
      */
      context.drawImage(this.videoElement, 0, 0, this.canvasElement.width, this.canvasElement.height);

      // 将拍照的图片转换为base64格式
      this.photoDataUrl = this.canvasElement.toDataURL('image/jpeg');
      //this.copyContentH5(this.photoDataUrl)
      request.post(process.env.VUE_APP_PX_URL+'/uua/verifyFace',{
        nameText:this.form.nameText,
        numberText:this.form.numberText,
        idPhoto:this.photoDataUrl
      }).then(res=>{
        // 调用 getTracks() 方法遍历摄像头视频流的所有轨道，并逐一停止它们，这样就关闭了摄像头：
        this.stream.getTracks().forEach((track) => {
          track.stop();
        });
        if (res.data=='success'){
          this.$router.push({path: '/authSuccess', query: {"numberText":this.form.numberText,"nameText":this.form.nameText}})
        }else {
          this.$router.push({path: '/authErr', query: {"numberText":this.form.numberText,"nameText":this.form.nameText}})
        }
      }).catch(err=>{
        this.$toast.fail(err.message)
      })

    },
  },
}
</script>
<style lang='scss' scoped>
page {height: 100%;width: 100%;}
.page-content {
  width: 100%;height: 100%;
  .containerV {
    width: 100%;height: 100%;display: flex;flex-direction: column;
    /* 标题 */
    .headerV {
      padding: 80px 0 80px;text-align: center;
      .top-tips1 {color: #333333;font-size: 30px;}
    }
    /* 拍摄区域 */
    .contentV {
      position: relative;display: flex;flex-direction: column;align-items: center;justify-content: center;
      width: 280px;height: 280px;border-radius: 50%;margin: 0 auto;z-index: 2;border: 10px solid #1568E0;overflow:hidden;
      .camera {object-fit: cover;}
    }

    /* 操作按钮 */
    .footerV {
      width: 100%;flex-grow: 1;display: flex;flex-direction: row;align-items: center;justify-content: center;
      .privacyV {
        padding-top: 80px;display: flex;flex-direction: row;align-items: center;justify-content: center;
        .text {
          font-size: 20px;color: #1C1C1C;text-align: center;line-height: 42px;margin-left: 15px;
          text {font-size: 30px;color: #00AAFF;text-align: center;line-height: 42px;}
        }
      }
      .bottom-tips-2 {margin-top: 20px;color: #999999;text-align: center;font-size: 26px;}

      .take-photo-bgV {
        width: 100%;margin-top: 90px;display: flex;flex-direction: row;align-items: center;justify-content: center;
        // 由于左边没有按钮，所以左边要便宜更大，以便是拍照按钮居中
        .btn-take-photo {margin: 0px 50px 0px 50px;width: 100px;height: 100px;background-image:url('../../assets/img/photo.png');background-repeat:no-repeat;background-size: 100% auto;}
        .btn-change-upload {left: 80px;width: 50px;height: 50px;background-image:url('../../assets/img/back.png');background-repeat:no-repeat;background-size: 100% auto;}
        .btn-change-camera {right: 80px;width: 50px;height: 50px;background-image:url('../../assets/img/changing.png');background-repeat:no-repeat;background-size: 100% auto;}
      }
      .confirmV {
        margin: 200px 100px 0px 100px;display: flex;flex-direction: row;align-items: center;justify-content: space-between;
        .btn-cancel {font-size: 32px;color: #1C1C1C;}
        .btn-ok {font-size: 32px;color: #00AAFF;}
      }
    }
  }
}
</style>
